import OpinionSection from '../../Sections/OpinionSection/OpinionSection';
import FaqSection from '../../Sections/FaqSection/FaqSection';
import ElasticForm from '../../Sections/ElasticForm/ElasticForm';

export default {
    init() {
        if( document.querySelector('body.ElasticTemplate') ) {
            OpinionSection.init();
            FaqSection.init();
            ElasticForm.init();
        }
    },
    finalize() {}
}