export default {
	init() {
		var t = this;
		if( document.body.getAttribute('data-callpage-display') == 'true' ) {
			t.key = document.body.getAttribute('data-callpage-key');
			if(document.readyState === 'complete') { 
				setTimeout( 
					function() {
						t.finalize();
					}, 
					5000 
				); 
			} else {
				document.addEventListener('readystatechange', function(e) {
					if( e.target.readyState === 'complete' ) { 
						setTimeout( 
							function() {
								t.finalize();
							}, 
							5000 
						); 
					}
				});
			}

		}
		document.body.removeAttribute('data-callpage-display');
		document.body.removeAttribute('data-callpage-key');
	},
	finalize() {
		var t = this;
		
		var getCookie = function(cname) {
			var objToday = new Date();
			var version = objToday.toISOString().split('T')[0].split('-').join('');
			var name = cname + '=';
			var decodedCookie = decodeURIComponent(document.cookie);
			var cookieArray = decodedCookie.split(';');
			for (var i = 0; i < cookieArray.length; i++) {
				var cookie = cookieArray[i];
				cookie = cookie.trim();
				if (cookie.indexOf(name) == 0) {
					return cookie.substring(name.length, cookie.length);
				}
			}
			return version;
		};
		var loadWidget = function(window, document) {
			// var __cp = {"id":"F-nJHvHORc9gZTYpdZHYLqmgC2Rac5zU0I8LVXMgYBk","version":"1.1"};
			var __cp = {"id": t.key ,"version":"1.1"};
			var cp = document.createElement('script');
			cp.type = 'text/javascript';
			cp.async = true;
			cp.src = "++cdn-widget.callpage.io+build+js+callpage.js".replace(/[+]/g, '/').replace(/[=]/g, '.') + '?v=' + getCookie('callpage-widget-version');
			var s = document.getElementsByTagName('script')[0];
			s.parentNode.insertBefore(cp, s);
			if (window.callpage) {
				alert('You could have only 1 CallPage code on your website!');
			} else {
				window.callpage = function(method) {
					if (method == '__getQueue') {
						return this.methods;
					} else if (method) {
						if (typeof window.callpage.execute === 'function') {
							return window.callpage.execute.apply(this, arguments);
						} else {
							(this.methods = this.methods || []).push({arguments: arguments,});
						}
					}
				};
				window.callpage.__cp = __cp;
				window.callpage('api.button.autoshow');
			}
		};
		return loadWidget(window, document);

	}

}