export default {
    init() {
        var t = this;
        document.querySelectorAll('section.ElasticForm').forEach( function( container, index ) {
            t.finalize( container );
        });
    },
    finalize( container ) {
        var t = this;
        var fields = container.querySelectorAll('.form__field');
        var form = container.querySelector('form.ElasticForm__form');

        var formSubmit = false;
        var formData = new FormData();
        var btnSubmit = container.querySelector('.form__submit');

        // -- label transform -- //
            fields.forEach( function( input, index ) {
                if( input.type != 'checkbox' ) {

                    input.addEventListener('focus', function() {
                        input.closest('.grid__field').classList.add('focus');
                    });
                    input.addEventListener('input', function() {
                        if( input.value ) {
                            input.closest('.grid__field').classList.add('focus');
                        } else {
                            input.closest('.grid__field').classList.remove('focus');
                        }
                    });
                    input.addEventListener('blur', function() {
                        if( !input.value ) {
                            input.closest('.grid__field').classList.remove('focus');
                        }
                    });

                }
            });
        // -- END -- //

        // -- submit -- //
            btnSubmit.addEventListener('click', function(e) {
                e.preventDefault();
                t.callback(null, form );

                if( formSubmit || btnSubmit.classList.contains('disable') ) {
                    return;
                }
                btnSubmit.classList.add('disable');


                // -- check -- //
                    fields.forEach( function( input, index ) {
                        if( input.type == 'checkbox' ) {
                            if( input.checked ) {
                                input.classList.remove('empty');
                            } else {
                                input.classList.add('empty');
                            }
                        } else if( input.getAttribute('required') || input.value ) {
                            if( input.value ) {
                                input.classList.remove('empty');
                            } else {
                                input.classList.add('empty');
                            }
                            if( input.type == "email" ) {
                                if( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input.value) ) {
                                    input.classList.remove('error');
                                } else {
                                    input.classList.add('error');
                                }
                            }
                        }
                    });

                    if( container.querySelectorAll('.form__field.empty').length > 0 ) {
                        t.callback( container.getAttribute('data-fields-empty'), form );
                        btnSubmit.classList.remove('disable');
                        return;
                    } else if( container.querySelectorAll('.form__field.error').length > 0 ) {
                        t.callback( container.getAttribute('data-fields-error'), form );
                        btnSubmit.classList.remove('disable');
                        return;
                    } 
                // -- END -- //


                // -- get data -- //
                    formData = new FormData();
                    let formFields = Array();
                    fields.forEach( function( input, index ) {
                        if( input.type != 'checkbox' && input.value ) {
                            formFields.push(
                                'label='+input.closest('div').querySelector('label').textContent.replace('*','') + "||"+
                                'value='+input.value+"||"+
                                "name="+input.name
                            );
                        }
                    });
                    formData.append('fields', formFields.join('&') );
                    formData.append('action','SendMailElastic');
                    formData.append('security',form.querySelector('input#security').value );
                    formData.append('ID', form.querySelector('input#post_id').value );
                // -- END -- //


                // -- send -- // 
                    fetch( window.location.origin+'/wp-admin/admin-ajax.php', {
                        method: 'POST',
                        credentials: 'same-origin',
                        body: formData,
                    })
                    .then( response => response.json() )
                    .then( data => {

                        if( data.status == 'success' ) {
                            formSubmit = true;
                            t.callback( container.getAttribute('data-form-success') , form );
                        } else {
                            t.callback( container.getAttribute('data-form-error') , form );
                            btnSubmit.classList.remove('disable');
                        }

                        if( document.querySelector('body').classList.contains('logged-in') ) {
                            console.log('Success');
                            console.log( data );
                        }

                    })
                    .catch( (error) => {
                        if( document.querySelector('body').classList.contains('logged-in') ) {
                            console.log( 'Error' );
                            console.log( error );
                        }
                    });
                // -- END -- //

            });
        // -- END -- //

    },
    callback( message = null, form = null /* node container */ ) {
        var t = this;
		form.querySelectorAll('.form__callback').forEach( function( callback, index ) {
			callback.remove();
		});

		if( message ) {
			form.querySelector('.form__submit').insertAdjacentHTML('beforebegin','<div class="form__callback"><div><p>'+message+'</p></div></div>');
		}
    }
}