// Defaults
import './style.scss';
import Router from './scripts/Router';
import LazyLoad from './scripts/lazyLoad';
import Subpage from '../src/Layout/Subpage';

// Pages
import homepage from '../src/Pages/Homepage/Homepage';
import reference from '../src/Pages/Reference/Reference';
import elastictemplate from '../src/Pages/ElasticTemplate/ElasticTemplate';

LazyLoad.init();
Subpage.init();
Subpage.slideUp();

const routes = new Router({
	homepage,
	reference,
	elastictemplate,
});

document.addEventListener("DOMContentLoaded", function() {
	
	routes.loadEvents();

});