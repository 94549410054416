import ScrollMagic from 'scrollmagic';
import SeparationSectionHeader from '../../Sections/SeparationSectionHeader/SeparationSectionHeader';
import RecommendationsSlider from '../../Sections/RecommendationsSlider/RecommendationsSlider';
import ContactForm from '../../Sections/ContactForm/ContactForm';
import StepCarousel from '../../Sections/StepCarousel/StepCarousel';

export default {
	init() {
		
		var container = document.querySelector('main.Homepage');

		if( container ) {

			SeparationSectionHeader.init();
    		RecommendationsSlider.init();
            ContactForm.init();
            StepCarousel.init();

            // -- diagram counter -- //

            	var valueDiagrams = document.querySelectorAll('.homepage__diagrams .grid__cell--value .cell__diagram');

            	if( valueDiagrams.length > 0 ) {

            		var controller = new ScrollMagic.Controller();

            		valueDiagrams.forEach( function( diagram,index ) {

            			new ScrollMagic.Scene({triggerElement: diagram, triggerHook: 1 })
							.addTo(controller)
							.offset(0)
							.on('enter', function() {

								var interval;
								interval = setInterval( function() {

										if( parseFloat( diagram.querySelector('p').innerText) >= diagram.getAttribute('data-value') ) {

											diagram.querySelector('p').innerText = diagram.getAttribute('data-value');
											clearInterval(interval);
											interval = null;

										} else {
											let tempVal = parseInt(diagram.getAttribute('data-value')/100);
											if( tempVal > 1 ) {
												diagram.querySelector('p').innerText = parseInt(diagram.querySelector('p').innerText) + tempVal;
											} else {
												diagram.querySelector('p').innerText = parseInt(diagram.querySelector('p').innerText) + 1;
											}
											

										}

									}, 
									4,
								);

							});

            		});

            	}

            // -- END -- //

    	}
    	
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
