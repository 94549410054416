import slick from 'slick-carousel';
import $ from 'jquery';
export default {
    init() {
        var t = this;
        document.querySelectorAll('section.OpinionSection').forEach( function( container,index ) {
            t.finalize( container );
        });

    },
    finalize( container ) {
        $(container.querySelector('.carousel__content')).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: container.querySelector('.carousel__button--prev'),
            nextArrow: container.querySelector('.carousel__button--next'),
            rows: false
        })
    }
}