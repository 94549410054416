import smoothscroll from "smoothscroll-polyfill";
export default {
	init() {

		var container = document.querySelector('section.homepageHeader');

		if( container ) {

			container.querySelectorAll('.Button').forEach( function( item, index ) {
	    		item.addEventListener('click', function(e) {
	    			
		    		if( item.getAttribute('href').split('#')[1] && document.querySelector('#'+item.getAttribute('href').split('#')[1] ) ) {
						e.preventDefault();

						smoothscroll.polyfill();
			            window.scroll({
			                top: document.querySelector('#'+item.getAttribute('href').split('#')[1] ).offsetTop - document.querySelector('header').offsetHeight ,
			                left: 0,
			                behavior: 'smooth'
			            });
						
					}

		    	});
	    	});	

		}
	},
	finalize() {

	}
}