import slick from 'slick-carousel';
import $ from 'jquery';

export default {

	init() {	

		var container = document.querySelector('section.RecommendationsSlider');

		if( container ) {

			// -- carousel left -- //

				var carouselLeft = container.querySelector('.row__carousel--left');

				$(carouselLeft).slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					// dots : true,
					// autoplay: true,
					// autoplaySpeed: 30000,
					rows: false,
				});

			// -- END -- //


			// -- carousel right -- //

				var carouselRight = container.querySelector('.row__carousel--right');

				$(carouselRight).slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					// dots : true,
					// autoplay: true,
					// autoplaySpeed: 30000,
					rows: false,
				});

			// -- END -- //

		}

	},
	finalize() {

	}

}