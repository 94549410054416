export default {
    init() {
        var t = this;
        document.querySelectorAll('.FaqSection').forEach( function( container, index ) {
            t.finalize( container );
        });
    },
    finalize( container ) {
        container.querySelectorAll('.single__button').forEach( function( button, index ) {
            button.addEventListener('click', function() {
                if( button.classList.contains('active') ) {
                    button.classList.remove('active');
                    button.closest('.content__single').querySelector('.single__answer').style.height = '0px';
                } else {
                    button.classList.add('active');
                    button.closest('.content__single').querySelector('.single__answer').style.height = button.closest('.content__single').querySelector('.answer__content').offsetHeight + 'px';
                }
            });
        });
        window.addEventListener('resize', function() {
            container.querySelectorAll('.single__button.active').forEach( function( button, index ) {
                button.closest('.content__single').querySelector('.single__answer').style.height = button.closest('.content__single').querySelector('.answer__content').offsetHeight + 'px';
            });
        });
        
    }
}